import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Réseaux & Télécommunications",
          "Internet des Objets Mobilité",
          "Systèmes & Réseaux",
          "Apprenti chez ISIRIS",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
