import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "start" }}>
            Je m'appelle <span className="purple">Fatih KILIC</span>.
            <br />
            Je suis actuellement alternant en tant que <span className="purple">technicien informatique</span> chez <span className="purple">ISIRIS</span>.
            <br />
            Je suis en dernière année de <span className="purple">BUT Réseaux & Télécommunications</span> spécialisé dans l'<span className="purple">Internet des Objets Mobilité </span> 
            à Montbéliard.
            <br />
            <br />
            En dehors de l'informatique, voici quelques autres activités que j'aime :
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Voyager
            </li>
            <li className="about-activity">
              <ImPointRight /> Faire de la mécanique
            </li>
            <li className="about-activity">
              <ImPointRight /> Jouer aux jeux vidéo
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
