import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiProxmox,
  SiVirtualbox
} from "react-icons/si";
import {   
  DiWindows,
  DiLinux,
  DiDocker,
  DiRasberryPi
} from "react-icons/di"

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>VScode</h6>
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>Windows</h6>
        <DiWindows />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>Linux</h6>
        <DiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>Proxmox</h6>
        <SiProxmox />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>Docker</h6>
        <DiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>VirtualBox</h6>
        <SiVirtualbox />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <h6 style={{color: "#daa2f5"}}>RasberryPi</h6>
        <DiRasberryPi />
      </Col>
    </Row>
  );
}

export default Toolstack;
