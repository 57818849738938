import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineMail
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LAISSEZ-MOI ME <span className="purple"> PRÉSENTER </span> 
            </h1>
            <p className="home-about-body">
              Je suis passionné par l'informatique, plus particulièrement par les réseaux et télécommunications.
              <br />
              <br />Je maîtrise 
              <i>
                <b className="purple"> l'administration systèmes Windows et Linux, la téléphonie d'entreprise,... </b>
              </i>
              <br />
              <br />
              Mes domaines d’intérêt sont&nbsp;
              <i>
                <b className="purple">la mise en place et la virtualisation de services ou de serveurs</b>
              </i>,
                plus généralement dans {" "}
              <i>
                <b className="purple">
                  les réseaux, les systèmes et le cloud computing.&nbsp;
                </b>
              </i>
                Je m'intéresse également aux domaines liés à l'
              <i>
                <b className="purple">
                  IoT et aux réseaux cellulaires.
                </b>
              </i>
              <br />
              <br />
              Dès que possible, j'applique ma passion en travaillant sur des projets pratiques, 
              notamment avec des outils modernes 
              <i>
                <b className="purple"> d’administration réseau et de virtualisation </b> 
              </i>
              
              ainsi qu’en explorant des solutions
              <i>
                <b className="purple">
                  {" "}
                  innovantes
                </b>
              </i>
              &nbsp;pour améliorer la
              <i>
                <b className="purple"> connectivité et la gestion des infrastructures.</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>N'hésitez pas à me <span className="purple">contacter</span></h1>
            <p>
              <span className="purple">Retrouvez-moi ici</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Fatih-RT"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:contact@fatih-kilic.fr"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/kilic-fatih/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
