import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import datacenter from "../../Assets/Projects/datacenter.png";
import beerok from "../../Assets/Projects/beerok.png";
import minecraft from "../../Assets/Projects/minecraft.png";
import WorldSkills from "../../Assets/Projects/worldskills.png";

function Projects() {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleCardClick = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedProject(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">projets </strong> réalisés
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={beerok}
              isBlog={false}
              title="Mise en place d'un réseau d'entreprise multi-sites"
              description={ `Nous devions répondre à un appel d’offres d’une entreprise fictive dénommée "Beerok", 
                spécialiste de la chaussure sportive, souhaitant restructurer son infrastructure réseau et informatique sur l’ensemble de ses sites situés en France.` }
              ghLink="https://github.com/Fatih-RT/Beerok/wiki/Beerok"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={minecraft}
              isBlog={false}
              title="Réseaux IoT"
              description="Ce projet a pour but de créer une infrastructure IoT permettant à deux joueurs de jouer à une partie spéciale de Minecraft, en réseau, avec des gants connectés qui leur fournissent des superpouvoirs et pièges pour battre leur adversaire."
              ghLink="https://github.com/Fatih-RT/Mise-en-place-d-un-reseau-IoT"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={datacenter}
              isBlog={false}
              title="Sécurisation d'un datacenter"
              description="Ce projet vise à établir un système de sécurité avancé pour un datacenter en utilisant la technologie IoT. Il implique la mise en place de différents capteurs dans des zones spécifiques (rouge, jaune, verte) pour surveiller et contrôler l'accès ainsi que les conditions environnementales."
              ghLink="https://github.com/Chadi25/SA-4.IOM.01-Impl-menter-les-r-seaux-et-protocoles-sans-fil-d-di-s-l-IoT/tree/main"
            />
          </Col>



        </Row>
      </Container>
        <h1 className="project-heading">
          Mes <strong className="purple">projets </strong> en cours
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={WorldSkills}
              isBlog={false}
              title="Administration de systèmes et réseaux informatique  WorldSkills"
              description="Mise en place de l'infrastructure réseau pour l'événement WorldSkills Competition 2024, qui se tiendra à Lyon en septembre 2024. Une société dédiée, appelée WorldSkills Lyon 2024 (WSL2024), a été créée pour superviser l'organisation complète de cet événement. En raison du grand nombre de tâches nécessaires, cette entreprise prévoit d'embaucher environ 120 employés, ce qui entraîne une augmentation des besoins en informatique."
              ghLink="https://github.com/Fatih-RT/"
            />
          </Col>

        </Row>
    </Container>
  );
}

export default Projects;
